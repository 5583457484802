const config = {
    env: "PROD",
    cacheEnabled: true,
    GSheetAccessKey: '',
    WebsiteConfigURL: '',
    SeriesInfoURL: '',
    DynConfigURL: '',

    data: {
        prod: {
            config: "/data/DYN-CONFIG.json",
            series: "/data/SERIES.json",
            episodes: "/data/EPISODES.json",
            pages: "/data/PAGES.json",
            panels: "/data/PANELS.json",
        }
    },

    defaultImageUrl: 'https://i.imgur.com/Pdii38C.png',
}

export default config